<template>
  <div>
    <p v-if="eventText">{{ eventText }}</p>
    <component
      :is="component"
      :listKey="listKey"
      :name="name"
      :config="config"
      :setupItemsCallback="setupItemsCallback"
      :filterValue="filterValue"
    ></component>
  </div>
</template>

<script>
export default {
  props: {
    listKey: String,
    name: String,
    config: Object,
    setupItemsCallback: Function,
  },
  components: {
    "liff-list-filter-item-tab": () =>
      import("@/components/liffList/listFilter/item/tab.vue"),
    "liff-list-filter-item-date": () =>
      import("@/components/liffList/listFilter/item/date.vue"),
  },
  data: () => ({
    eventText: null,
  }),
  computed: {
    filterValue() {
      if (!this.listFilter) return null;
      return this.listFilter[this.name];
    },
    type() {
      return this.config.type;
    },
    component() {
      return this.$options.components[`liff-list-filter-item-${this.type}`];
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`];
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`];
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`];
    },
  },
  methods: {
    detect(e) {
      this.eventText = e.keyCode;
    },
  },
};
</script>